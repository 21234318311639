import React from "react";

export const Services = (props) => {
  return (
    <div id="solutions" className="text-center">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <div className="section-title">
          <h2>Solutions</h2>
          <p>
          DomeTechs js offering a set of solutions for connectivity service providers that covers:
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i className={d.icon} style={{ fontSize: 45 }}></i>
                  <div className="service-desc">
                    <h4>{d.name}</h4>
                  </div>
                  <br></br>
                  <br></br>
                </div>
              ))
            : "loading"}
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
       <div className="container">
        <div className="section-title">
          <h2>Banking Sectors</h2>
          <p>
            In addition to our services in the mobile security and app development for banking industry, we also serve our clients by supplying other many value added products. Examples include
          </p>
        </div>
      <h5>
      </h5>
      <div className="row">
      <div className="col-md-6">
                  <i className="fa fa-lock" style={{ fontSize: 45 }}></i>
                  <div className="service-desc">
                    <h4>Secured paper and banknote substrate supply</h4>
                  </div>
                  <br></br>
                  <br></br>
                </div>
                <div className="col-md-6">
                  <i className="fa fa-genderless" style={{ fontSize: 45 }}></i>
                  <div className="service-desc">
                    <h4>banking cash cycle management</h4>
                  </div>
                  <br></br>
                  <br></br>
                </div>
      </div>
      </div>
      
    </div>
  );
};
