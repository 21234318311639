import React from "react";
export const Media = () => {
  return (
    <div id="media">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <div className="section-title text-center">
          <h2>Digital Media Productions</h2>
        </div>
        <div className="text-center">
          <p>
          Digital media production is the backbone of the internet. Ifs the generation of everything fcom video and audio to interactive online experiences.
           Harnessing media production skills for onl"ne ma keting and business promotions. 
           Digital media allows savvy companies an ongoing focus group experience with their fans and potential customers. 
           Content can be user generated or brand created, but it must be compelling. engaging and shareable.
            Digital media visual content is not just about b oadcasting your message but rather about creating open dialogue and relationships with your consumers. 
          </p>
        </div>
      
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};
