import React from "react";

export const Partners = (props) => {
  return (
    <div id="partners" className="text-center">
      <div  className="container">
      <div className="section-title">
          <h2>Our Partners</h2>
        </div>
        <div className="row">
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/GD.jpg" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/RapidSSLLogo.webp" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/senha.png" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/secunet.png" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/zecurion.png" className="team-img" alt="..."   />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/Transtrack.png" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/sangfor.png" className="team-img" alt="..."  />
        </div>
        <div  className="col-md-3 col-sm-6 col-xs-6 team">
                  <img src="img/team/eocortex.png" className="team-img" alt="..."  />
        </div>
        </div>
      </div>
      <br></br>
      <br></br>
    </div> );
};
