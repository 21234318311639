import React from "react";

export const Infrastructure = () => {
  return (
    <div id="infrastructure">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <div className="section-title text-center">
          <h2>IT Infrastructure hardware/software</h2>
        </div>
        <div className="text-center">
          <p>
          Through our strategic partners. Dome Technologies could prov!de hardware. software and spare parts for all IT infrastructure components. 
          Years, of expertise and innovation thinking have gone into creatjng reltable, scalable and energy-efficient hardware. These improvements offer some of the most modem systems in the industry, which are the core buUding blocks fer the mission-critical enterprise computing, enterprise appHcaUon environments and virtuaJization. 
          Dome Technologies provides sys ems that help datacenters manager reduce costs, conserve energy and operate more efficiently. 
          </p>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
